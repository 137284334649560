import {store} from "../redux/store";
import {setCurrentEventPlaceId} from "../redux/actions/setCurrentEventPlaceId";
import {getCurrentEventPlaceId} from "../util/event-place";
import {setGuestEvents} from "../redux/actions/setGuestEvents";
import {listGuestEvents} from "../action/listGuestEvents";

export const eventsMusterLoader = async (args) => {
    store.dispatch(setCurrentEventPlaceId({ eventPlaceId: getCurrentEventPlaceId() }));
    store.dispatch(setGuestEvents({ guestEvents: await listGuestEvents({ typeId: args.params.typeId }) }));
    return true;
}
