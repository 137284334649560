import {store} from "../redux/store";
import {setInfopages} from "../redux/actions/setInfopages";
import {listInfopages} from "../action/listInfopages";
import {setArticleCategories} from "../redux/actions/setArticleCategories";
import {listArticleCategories} from "../action/listArticleCategories";
import {listEventTypes} from "../action/listEventTypes";
import {setEventTypes} from "../redux/actions/setEventTypes";
import {listEventPlaces} from "../action/listEventPlaces";
import {setEventPlaces} from "../redux/actions/setEventPlaces";
import {setCurrentEventPlaceId} from "../redux/actions/setCurrentEventPlaceId";
import {getCurrentEventPlaceId} from "../util/event-place";

export const layoutLoader = async (args) => {
    store.dispatch(setArticleCategories({ articleCategories: await listArticleCategories() }));
    store.dispatch(setEventTypes({ eventTypes: await listEventTypes() }));
    store.dispatch(setInfopages({ infopages: await listInfopages() }));
    store.dispatch(setEventPlaces({ eventPlaces: await listEventPlaces() }));
    store.dispatch(setCurrentEventPlaceId({ eventPlaceId: getCurrentEventPlaceId() }));
    return true
}
