import moment from "moment";
import {useEffect, useState} from "react";

export const Calendar = (props) => {
    const [months, setMonths] = useState([]);
    const [month, setMonth] = useState(moment().month());
    const [days, setDays] = useState([]);

    useEffect(() => {
        const months = [];
        const currentMonth = moment().month();
        let i = 0;
        moment.months().forEach(month => {
            if (i >= currentMonth) {
                months.push({ index: i, name: month });
            }
            i++;
        })
        setMonths(months);
    }, [])

    useEffect(() => {
        const days = []
        const daysInMonth = moment({ month }).daysInMonth();
        const mo = moment({ month, day: 1 });
        const startDay = mo.format(`YYYY-MM`) === moment().format(`YYYY-MM`) ? parseInt(moment().format(`DD`)) : 1;
        for (let i = startDay; i <= daysInMonth; i++) {
            const m = moment({ month, day: i });
            const classes = [ `day` ];

            if (m.format(`YYYY-MM-DD`) === moment().format(`YYYY-MM-DD`)) {
                classes.push(`day--current`);
            }

            if (props.activeDates.includes(m.format("DD-MM-YYYY"))) {
                classes.push(`day--active`);
            }

            if (props.selectedDate && (m.format(`YYYY-MM-DD`) === props.selectedDate.format(`YYYY-MM-DD`))) {
                classes.push(`day--selected`);
            }

            days.push({ classes, moment: m });
        }
        setDays(days);
    }, [month, props.selectedDate, props.activeDates]);

    const selectDate = (day) => {
        if (!day.classes.includes(`day--active`) || props.onDateSelect === undefined) {
            return;
        }
        props.onDateSelect(day.moment);
    }

    const monthSelect = (month) => {
        setMonth(month.index);
        props.onMonthSelect();
    }

    return (
        <div className="agora-calendar">
            <div className="month-select" onClick={() => props.onMonthSelectOpen()} style={ props.showMonthSelect ? { visibility: `hidden` } : {} }>
                { months.find( m => m.index === month )?.name }
            </div>
            <div className="month-list" style={ props.showMonthSelect ? { display: `flex` } : {} }>
                { months.map(month => (
                    <div className="month" onClick={() => monthSelect( month )}>{ month.name }</div>
                )) }
            </div>
            <div className="day-select">
                { days.map(day => (
                    <div className={ day.classes.join(` `) } onClick={() => selectDate(day)}>{ day.moment.date() }</div>
                ))}
            </div>
        </div>
    )
}