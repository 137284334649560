import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import 'react-calendar/dist/Calendar.css';
import moment from 'moment'
import ProgramCard from "../components/cards/ProgramCard"
import tovabb from "../assets/media/icons/tovabb nyil.svg"
import { MDBContainer, MDBCol, MDBRow } from 'mdb-react-ui-kit';
import {useSelector} from "react-redux";


const AllandoProgramokPage = () => {

  moment.updateLocale('hu', {
    months : [
        "Január", "Február", "Március", "Április", "Május", "Június", "Július",
        "Augusztus", "Szeptember", "Október", "November", "December"
    ]
  }); 
  const navigate = useNavigate();
  const [direction, setDirection] = useState(window.outerWidth);
  const { recurringEvents } = useSelector(state => state);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  const handleWindowSizeChange = () => {
    setDirection(window.visualViewport.width);
  };

  useEffect(() => {
    window.visualViewport.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.visualViewport.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <>
    <section className="kovetkezo-programok-section events-page" id ="kovetkezo-programo-section">
      <MDBContainer style={{maxWidth: "1008px"}} className='p-0'>
        <div className="navigate-div" style={{marginTop: 0}}>
          <button onClick={() => {  navigate('/home')}}>Főoldal</button>
          <img src={tovabb} alt="tovabb"/>
          <button>Állandó programok</button>
        </div>
        <div>
          <div className="program-title">
            <span>Állandó programok</span>
          </div>
          <MDBRow className="m-0 programok-row">
            {recurringEvents?.map((k,n) => {return (
                <MDBCol md="4" className="programok-col px-0" key={n}>
                    <ProgramCard msg={k} allandoprogramok={true} kovetkezoprogramok={true} categoryName={"Állandó programok"} linkText={"allandoprogramok"}/>
                </MDBCol>
            )})}
          </MDBRow>
        </div>
      </MDBContainer>
    </section>
    </>
  );
};

export default AllandoProgramokPage;
