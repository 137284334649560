import React from 'react';
import {Provider} from "react-redux";
import {RouterProvider} from 'react-router-dom';
import AppContextProvider from './AppContextProvider';
import {router} from "./router";
import {store} from './redux/store';

function App() {

    return (
        <AppContextProvider>
            <Provider store={store}>
                <RouterProvider router={router}/>
            </Provider>
        </AppContextProvider>
    );
}

export default App;
