import React, { Component } from 'react';
import AppContext from './AppContext';
import InterAxios from './Service/InterAxios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const getAuthToken = () => {
  return cookies.get('authtoken')?.token;
}

class AppContextProvider extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      interAxios: new InterAxios('App', getAuthToken),
      id: 1,
    };
  }

  setState(state) {
    super.setState(function(prevState, props) {
      let newState = {...prevState, ...state};
      window.localStorage.setItem('state', JSON.stringify(newState));
      return {
        ...newState
      };
    })
  }

  getState = () => {
    let stateStr = window.localStorage.getItem('state');
    let stateObj = JSON.parse(stateStr);
    return stateObj;
  }

  render() {
      return (
          <AppContext.Provider
              value={{
                interAxios: this.state.interAxios,
                getState: this.getState
              }}
          >
              {this.props.children}
          </AppContext.Provider>
      );
  }
}

export default AppContextProvider;
