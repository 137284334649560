import React from "react";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalTitle,
  MDBModalBody
} from 'mdb-react-ui-kit';
import {closeModal} from "../../redux/actions/closeModal";
import { store } from "../../redux/store"


const HirlevelModal = (props) => {


    return (
    <MDBModal staticBackdrop tabIndex='-1' closeOnEsc={false} show={props.visible}  className="altalanos-modal">
      <MDBModalDialog scrollable centered>
        <MDBModalContent>
          <MDBModalBody>
          <MDBModalTitle>
              <span className="modal-title">Sikeresen feliratkozott!</span>
          </MDBModalTitle>
            <div className="justify-content-center d-flex align-ites-center3" >
              <span className="modal-description">Hamarosan érdeklődésének megfelelő hírleveleket küldünk Önnek.</span>
            </div>
            <div className="justify-content-center d-flex align-ites-center pt-3" >
              <button type="button" className="muster-button kiemelt" onClick={() => store.dispatch(closeModal('newsletter'))}>
                <span>BEZÁRÁS</span>
              </button>
            </div>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
export default HirlevelModal;
