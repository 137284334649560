import { useNavigate } from "react-router-dom";
import moment from 'moment';
import {BlurhashImage} from "../blurhash/BlurhashImage";
import {useEffect, useRef, useState} from "react";

const HirekFooldalCard  = (props) => {
  const navigate = useNavigate();
  const titleRef = useRef();
  const [fontSize, setFontSize] = useState(22);

  useEffect(() => {
      if (window.outerWidth > 811 && titleRef.current) {

          if (fontSize === 16) {
              return;
          }

          if (titleRef.current.scrollHeight > 56) {
              setFontSize(fontSize - 1);
          }
      }
  }, [fontSize, titleRef]);

  return (
    <div className="hirekfooldal-card-div" onClick={() => {navigate('/hirek/hirekdetailpage/' + props.msg.id);}}>
        <div className="image-div">
            <BlurhashImage image={props.msg.listImage} blurhash={props.msg.listImageBlurhash} />
        </div>
        <div className="hirefooldal-text">
          <div className="title-div" ref={ titleRef }>
              <span style={fontSize < 22 ? { fontSize: `${ fontSize }px` } : undefined}>{props.msg.title}</span>
          </div>
          <div className="text-div">
              <span>{moment.unix(props.msg.datePublished).locale('hu').format('YYYY.MM.DD.')}</span>
          </div>
        </div>
        
    </div>
  );
};

export default HirekFooldalCard ;
