// react imports
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    MDBContainer, MDBAccordion, MDBAccordionItem
} from 'mdb-react-ui-kit';

import tovabb from "../assets/media/icons/tovabb nyil.svg"
import {useSelector} from "react-redux";


const FelnottkepzesPage = (props) => {

    const navigate = useNavigate();

    const [direction, setDirection] = useState(window.outerWidth);
    const { infopages } = useSelector(state => state);

    const handleWindowSizeChange = () => {
        setDirection(window.visualViewport.width);
    };

    useEffect(() => {
        window.visualViewport.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.visualViewport.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    return (
        <>
            {props.infopageById && (
                <section className="felnottkepzes-section" id="felnottkepzes-section">
                    <MDBContainer style={{maxWidth: "1008px"}} className='p-0'>
                        <div className="kapcsolat-map-div">
                            <div className="one-col-div">
                                <div className="navigate-div" style={{marginTop: 0}}>
                                    <button onClick={() => {
                                        navigate('/home')
                                    }}>Főoldal
                                    </button>
                                    <img src={tovabb} alt="tovabb"/>
                                    <button onClick={() => {
                                        navigate(infopages.find(i => i.title === "Szervezetünk") ? ('/infosfooldal/' + infopages.find(i => i.title === "Szervezetünk").id) : `/home`)
                                    }}>Szervezetünk
                                    </button>
                                    <img src={tovabb} alt="tovabb"/>
                                    <button>Felnőttképzés</button>
                                </div>
                                <div className="up-div mb-0">
                                    <h3>Felnőttképzés</h3>
                                    {props.infopageById?.map((k, n) => {
                                        return (
                                            <div className="accordion-div" key={n}>
                                                <div className="title-text">
                                                    <span>{k.title}</span>
                                                </div>
                                                <div className="accordion-tag">
                                                    <MDBAccordion flush initialActive={1}>
                                                        {k.chapters.map((l, m) => {
                                                            return (
                                                                <MDBAccordionItem collapseId={m + 1}
                                                                                  headerTitle={l.name} key={m}>
                                                                    {l.description}
                                                                </MDBAccordionItem>

                                                            )
                                                        })}

                                                    </MDBAccordion>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                        </div>
                    </MDBContainer>
                    {direction > 500 && (
                        <div className="background-div"/>
                    )}
                </section>
            )}


        </>
    );
};

export default FelnottkepzesPage;
