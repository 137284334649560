import Cookies from "universal-cookie";
import InterAxios from "../Service/InterAxios";
import {store} from "../redux/store";

const defaultOptions = {
    placeId: undefined,
    limit: undefined,
    from: undefined,
    categoryId: undefined
}

export const listArticles = async (options = defaultOptions) => {

    const cookies = new Cookies();
    const interAxios = new InterAxios('App', () => cookies.get('authtoken')?.token);

    if (options.placeId === undefined) {
        options.placeId = store.getState().currentEventPlaceId;
    }

    return new Promise((resolve, reject) => {
        interAxios.get(`/articles?placeId=${ options.placeId }${ options.limit === undefined ? `` : `&limit=${ options.limit }` }${ options.from === undefined ? `` : `&from=${ options.from }` }${ options.categoryId === undefined ? `` : `&categoryId=${ options.categoryId }` }`, function (response) {
            resolve(response.data);
        }, function (error) {
            reject(error.response?.data);
        });
    })
}