// react imports
import React, {useEffect, useState} from "react";
import {useNavigate, useLocation} from "react-router-dom";

import {
    MDBContainer
} from 'mdb-react-ui-kit';

import tovabb from "../assets/media/icons/tovabb nyil.svg"


const PdfReadPage = (props) => {
    const location = useLocation();
    const navigate = useNavigate();


    const [pdf, setPdf] = useState();

    useEffect(() => {
        setPdf(location.state.pdf)
    }, []);

    return (
        <>
            {pdf && (
                <section className="structure-section" id="structure-section">
                    <MDBContainer style={{maxWidth: "1270px"}} className='p-0'>
                        <div className="kapcsolat-map-div">
                            <div className="one-col-div pb-4">
                                <div className="navigate-div">
                                    <button onClick={() => {
                                        navigate('/home')
                                    }}>Főoldal
                                    </button>
                                    <img src={tovabb} alt="tovabb"/>
                                    <button>Programfüzet</button>
                                </div>
                                <div className="up-div mb-0">
                                    <h3>Programfüzet</h3>
                                    <div className="mt-4">
                                        <iframe src={pdf}
                                                height={window.innerWidth / 1920 > 1 ? 910 : window.innerWidth > 1270 ? (window.innerWidth / 1920) * 910 : window.innerWidth * 1.5}
                                                width={window.innerWidth / 1920 > 1 ?
                                                    1270 : window.innerWidth > 1270 ? (window.innerWidth / 1920) * 1270 : window.innerWidth - 40}
                                                allow="autoplay" title="myFrame"></iframe>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </MDBContainer>
                </section>
            )}


        </>
    );
};

export default PdfReadPage;
