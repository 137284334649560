import {store} from "../redux/store";
import {listArticles} from "../action/listArticles";
import {setArticles} from "../redux/actions/setArticles";
import {setCurrentEventPlaceId} from "../redux/actions/setCurrentEventPlaceId";
import {getCurrentEventPlaceId} from "../util/event-place";

export const articlesListLoader = async () => {
    if (store.getState().articles.length === 0) {
        console.log('loading articles list')
        store.dispatch(setCurrentEventPlaceId({ eventPlaceId: getCurrentEventPlaceId() }));
        store.dispatch(setArticles(await listArticles({ limit: 9 })));
    }
    return true;
}
