import React, { useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";
import OrganizationstructurePage from './OrganizationstructurePage';
import FelnottkepzesPage from './FelnottkepzesPage';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import CarouselComponent from '../components/carousel/CarouselComponent.js'
import { MDBContainer, MDBTable, MDBTableBody } from 'mdb-react-ui-kit';

import tovabb from "../assets/media/icons/tovabb nyil.svg"
import {BlurhashImage} from "../components/blurhash/BlurhashImage";
import {useSelector} from "react-redux";
import {OpenStreetMap} from "../components/openstreetmap/OpenStreetMap";

const InfoPage = () => {

  const navigate = useNavigate();
  const { infopage, infoPageSource } = useSelector(state => state)
  const [sliderImages, setSliderImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [direction, setDirection] = useState(window.outerWidth);
  const [openIndex, setOpenIndex] = useState(0);

  useEffect(() => {
    setTimeout(() => window.scrollTo({
      top: 0,
      behavior: "smooth"
    }), 100);
  }, [ infopage ]);

  const handleWindowSizeChange = () => {
    setDirection(window.visualViewport.width);
  };

  useEffect(() => {
    window.visualViewport.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.visualViewport.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const getReplaceText = (text) => {
    return text.toLowerCase().replaceAll("ö", "o").replaceAll("ü", "u").replaceAll(".", "").replaceAll("í", "i").replaceAll("ó", "o").replaceAll("ú", "u").replaceAll("é", "e").replaceAll("á", "a").replaceAll("ő", "o").replaceAll("ű", "u").replaceAll(",", "").replaceAll(" ", "_")
  }

  const truncateString = (str, num) => {
    if (str !== undefined && str.length <= num) {
        return str
      }
    return str.slice(0, num) + '...'
  }
  
  return (
    <>
      {infopage && infoPageSource !== "/organizationstructure" && infoPageSource !== "/adulteducation" && (
      <section className="kapcsolat-map-section" id ="kapcsolat-map-section" >
        <MDBContainer style={{maxWidth: "1008px"}} className='p-0'>
          <div className="kapcsolat-map-div">
            <div className="one-col-div">
              <div className="navigate-div" style={{ marginTop: 0 }}>
                <button onClick={() => { navigate('/home')}}>Főoldal</button>
                <img src={tovabb} alt="tovabb"/>
                {infopage.crumbs?.length > 1 && (
                <>
                {direction > 811 && (
                    <>
                    <button onClick={() => {navigate('/infosfooldal/' +  infopage.crumbs[0].id); }}>{infopage.crumbs[0].title}</button>
                    <img src={tovabb} alt="tovabb"/>
                    </>
                )}
                { infopage.crumbs?.filter((k,n) => n > 0 && n < infopage.crumbs?.length-1).map((k,n) => {
                    let path = [];
                    for (const cr of infopage.crumbs) {
                      path.push(getReplaceText(cr.title));
                      if (k.id === cr.id) {
                        break;
                      }
                    }
                    return (
                      <>
                        <button onClick={() => {navigate('/infos/' + path.join('/'));}}>{k.title}</button>
                        <img src={tovabb} alt="tovabb"/>
                      </>
                    )}
                )}
                </> 
                )}
              
                <button>{truncateString(infopage.title, 30)}</button>
              </div>
            
                <div className="up-div mb-0">
                  <h3>{infopage.title}</h3>
                  {infopage.image !== null && (
                      <BlurhashImage className="tbkartya-image" image={infopage.image} blurhash={infopage.imageBlurhash} />
                  )}
                  {window.location.pathname.split("/")[window.location.pathname.split("/").length-1] === "kozossegi_szolgalat" && (
                    <>
                    {infopage.relatedLinks && infopage.relatedLinks.length > 0 && (
                    <>
                          {infopage.relatedLinks.map((k, n) => { return (
                            <div className="loadmore-div" key={n}>
                              <button  className="muster-button kiemelt" onClick={() => window.open(k.link, '_blank')}><span className="button-text">{k.title}</span></button>
                            </div>
                          )})}
                    </>
                  )}
                    </>
                  )}
                  
                  {window.location.pathname.split("/")[window.location.pathname.split("/").length-1] === "agora_nonprofit_kft" ? <>
                    <div className="description" dangerouslySetInnerHTML={{__html: infopage.content }}></div>
                    {infopage.documents && infopage.documents.length > 0 && (
                      <div className="documents">
                        <MDBTable hover>
                        {infopage.documents.map((k,n) => {return (
                          <MDBTableBody key={n}>
                          <tr>
                            <th scope='row'>{n+1}</th>
                            <td><div className="documents-download">
                                  <a href={k.url} target="_blank" rel="noreferrer">
                                    {k.name !== null ? k.name : "Dokumentum"}
                                  </a>
                                </div>
                            </td>
                          </tr>
                        </MDBTableBody>
                        )})}
                        </MDBTable>
                      
                      </div>
                    )}
                  </>:
                  <>
                    {infopage.documents && infopage.documents.length > 0 && (
                      <div className="documents">
                        <MDBTable hover>
                        {infopage.documents.map((k,n) => {return (
                          <MDBTableBody key={n}>
                          <tr>
                            <th scope='row'>{n+1}</th>
                            <td><div className="documents-download">
                                  <a href={k.url} target="_blank" rel="noreferrer">
                                    {k.name !== null ? k.name : "Dokumentum"}
                                  </a>
                                </div>
                            </td>
                          </tr>
                        </MDBTableBody>
                        )})}
                        </MDBTable>
                      
                      </div>
                    )}
                    <div className="description" dangerouslySetInnerHTML={{__html: infopage.content }}></div>
                    {infopage.children && infopage.children.length > 0 && (
                      <div className="documents">
                        <MDBTable hover>
                        {infopage.children.map((k,n) => {return (
                          <MDBTableBody key={n}>
                          <tr>
                            <td><div className="documents-download">
                                  <a onClick={() => { navigate('/infos/' + infopage.crumbs?.map(c => getReplaceText(c.title)).join('/') + '/' + getReplaceText(k.title));}}>
                                    {k.title}
                                  </a>
                                </div>
                            </td>
                          </tr>
                        </MDBTableBody>
                        )})}
                        </MDBTable>
                      
                      </div>
                    )}
                  </>}
                  {window.location.pathname.split("/")[window.location.pathname.split("/").length-1] !== "kozossegi_szolgalat" && (
                    <>
                    
                    {infopage.relatedLinks && infopage.relatedLinks.length > 0 && (
                    <>
                          {infopage.relatedLinks.map((k, n) => { return (
                            <div className="loadmore-div" key={n}>
                              <button  className="muster-button kiemelt" onClick={() => window.open(k.link, '_blank')}><span className="button-text">{k.title}</span></button>
                            </div>
                          )})}
                    </>
                    )}
                    </>
                  )}
                  <div className="helyszin-div">
                    {infopage.address !== "" && ( <>
                      <div className="title">
                        <span>Helyszín:</span>
                      </div>
                      <div className="address">
                        <span>{infopage.address}</span>
                      </div>
                    </>)}
                  
                    {infopage.lat !== null && (
                      <div className="program-maps">
                        <div className="map-keret" id="map-keret">
                            <OpenStreetMap lat={infopage.lat} lng={infopage.lng} />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="gallery">
                  {infopage.gallery && infopage.gallery.length > 0 &&(
                        <>
                        {infopage.gallery.length === 1 ?
                        <div className="image-items">
                          <img className="gallery-image-one" src={infopage.gallery[0].imageUrl} alt=""/>
                        </div>
                        :
                        <MDBContainer fluid className="gallery">
                          <div  className="gallery-title" >
                            <span>Galéria</span>
                          </div>
                          <CarouselComponent gallery={infopage.gallery} openLightboxlick={(n) => {setSliderImages(infopage.gallery); setOpen(!open); setOpenIndex(n)}}/>
                        </MDBContainer>
                        }
                        
                        
                        </>
                        
                    )}
                    {infopage.youtubeEmbedUrl && (
                        <div className="video">
                          <iframe src={infopage.youtubeEmbedUrl + "?autoplay=0&controls=1&loop=0"}
                              height={window.innerWidth/1920 > 1 ? 412 : window.innerWidth > 810 ? (window.innerWidth/1920)*412: (window.innerWidth-40)*9/16} 
                              width={window.innerWidth/1920 > 1 ? 
                                      710 : window.innerWidth > 810 ? (window.innerWidth/1920)*710 : window.innerWidth-40} allow="autoplay" title="myFrame"></iframe>
                        </div>
                    )}
                  </div>
                </div>
              
            </div>
          </div>
        </MDBContainer>
        {direction > 811 && (
          <div className="background-div">
            {infopage.awards?.length > 0 && (
              <div className="awards">
                <span>
                  Díjaink:
                </span><br/>
                {infopage.awards?.map((k,n) => {return (
                  <img className="awards-img" src={k.image} alt={n} key={n}/>
                )})}
              </div>
            )}
            
            
          </div>
        )}
      </section>
      )}
      {infopage && infoPageSource === "/organizationstructure" && (
        <OrganizationstructurePage infopageById={infopage}/>
      )}
      {infopage && infoPageSource === "/adulteducation" && (
        <FelnottkepzesPage infopageById={infopage}/>
      )}
      <Lightbox
        open={open}
        index={openIndex}
        close={() => setOpen(false)}
        slides={sliderImages.map(k => {return ({src: k.imageUrl})})}
        plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
      />
    </>
  );
};

export default InfoPage;
