import React, { useEffect, useState } from "react";
import {useNavigate, useParams} from "react-router-dom";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import tovabb from "../assets/media/icons/tovabb nyil.svg"
import { MDBContainer, MDBCol, MDBRow } from 'mdb-react-ui-kit';
import {BlurhashImage} from "../components/blurhash/BlurhashImage";
import {useDispatch, useSelector} from "react-redux";
import {pushGalleryPage} from "../redux/actions/pushGalleryPage";
import {getGalleryPage} from "../action/getGalleryPage";


const GaleriaPage = (props) => {

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [page, setPage] = useState(2);
  const [sliderImages, setSliderImages] = useState([]);
  const [open, setOpen] = useState(false);

  const { areMoreGalleryImages, eventPlace, galleryImages } = useSelector(state => state);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  const loadMoreImages = async () => {
    dispatch(pushGalleryPage(await getGalleryPage(params.id, page)));
    setPage(page + 1)
  };

  let galleries = [];
  if (eventPlace?.galleries && typeof eventPlace.galleries === "object" ) {
      if (Array.isArray(eventPlace.galleries)) {
          galleries = eventPlace.galleries;
      } else {
          galleries = Object.values(eventPlace.galleries);
      }
  }
  const galleryTitle = galleries.find(g => g.id == params.id)?.title || ``;

  return (
    <>
    <section className="galeria-kategoria-section" id ="galeria-kategoria-section" style={{paddingBottom: "85px", minHeight: "calc(100vh - 617px"}}>
      <MDBContainer style={{maxWidth: "1008px"}} className='p-0'>
            <div>
              <div className="navigate-div" style={{ marginTop: 0 }}>
                <div>
                  <button onClick={() => { navigate('/home')}}>Főoldal</button>
                  <img src={tovabb} alt="tovabb"/>
                </div>
                <div>
                  <button onClick={() => {navigate('/fotogallery')}}>Galéria</button>
                  <img src={tovabb} alt="tovabb"/>
                </div>
                <div>
                  <button>{galleryTitle}</button>
                </div>
              </div>
              <div className="galeria-title">
                <span>{galleryTitle}</span>
              </div>
              
              <MDBRow className="galeria-row">
                {galleryImages.map((k,n) => {return (
                  
                  <MDBCol md="2"  size="6" className="galeria-col" key={n}>
                    <div onClick={() => {setSliderImages(galleryImages); setOpen(!open)}}>
                      <BlurhashImage className="gallery-image" image={k.url} blurhash={k.imageBlurhash} />
                    </div>
                  </MDBCol>
                  
                )})}
            
              </MDBRow>
              
            </div>
            {areMoreGalleryImages && (
              <div className="d-flex justify-content-end mb-4" style={{paddingRight: "12px"}}>
                <button type="button" className="muster-button kiemelt-next" onClick={loadMoreImages}><span>Továbbiak </span></button>
              </div>)}
            <div className="loadmore-div" style={{paddingLeft: "12px"}}>
              <button type="button" onClick={() => navigate('/fotogallery')}><span>Vissza a galériába</span></button>
            </div>
      </MDBContainer>
    </section>
    <Lightbox
            open={open}
            close={() => setOpen(false)}
            slides={sliderImages.map(k => {return ({src: k.url})})}
            plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
        />
    </>
  );
};

export default GaleriaPage;
