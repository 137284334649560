import React, {useEffect} from "react";
import {useLoaderData, useNavigate} from "react-router-dom";
import moment from 'moment'
import ProgramCard from "../components/cards/ProgramCard"
import tovabb from "../assets/media/icons/tovabb nyil.svg"
import { MDBContainer, MDBCol, MDBRow } from 'mdb-react-ui-kit';
import "react-calendar/dist/Calendar.css";
import { useSelector } from "react-redux";

const ProgramokListPage = () => {

    moment.updateLocale('hu', {
        months: [
            "Január", "Február", "Március", "Április", "Május", "Június", "Július",
            "Augusztus", "Szeptember", "Október", "November", "December"
        ]
    });

    const loaderData = useLoaderData();
    const navigate = useNavigate();
    
    const { guestEvents } = useSelector(state => state)

    useEffect(() => {

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [
        loaderData.loadedAt
    ])

    return (
        <>
            <section className="kovetkezo-programok-section events-page" id="kovetkezo-programo-section">
                <MDBContainer style={{maxWidth: "1008px"}} className='p-0'>

                    <div className="navigate-div" style={{ marginTop: 0 }}>
                        <button onClick={() => {
                            navigate('/home')
                        }}>Főoldal
                        </button>
                        <img src={tovabb} alt="tovabb"/>
                        <button>További kiállítások</button>
                    </div>
                    <div>
                        <div className="program-title">
                            <span>További kiállítások</span>
                        </div>

                        <MDBRow className="programok-row">
                            {guestEvents?.map((k, n) => {
                                return (
                                    <MDBCol md="4" className="programok-col" key={n}>
                                        <ProgramCard msg={k} kovetkezoprogramok={false}/>
                                    </MDBCol>
                                )
                            })}
                        </MDBRow>
                    </div>


                </MDBContainer>
            </section>
        </>
    );
};

export default ProgramokListPage;
