
import axios from 'axios';
import Cookies from 'universal-cookie';

const { REACT_APP_BACKEND_URL } = process.env;

const cookies = new Cookies();

class InterAxios {
  constructor(name, getToken) {
    this.name = name;
    this.cancelTokenSource = axios.CancelToken.source();
    this.authToken = cookies?.get('authtoken')?.token;
    this.getToken = getToken;
    this.errorHandling.bind(this);
    this.catchFunction.bind(this);
    this.cancelHandling.bind(this);
    this.get.bind(this);
    this.post.bind(this);
    this.delete.bind(this);
    this.put.bind(this);
    this.urlPrefix = 'api/v1.0';
    //this.refreshUserData.bind(this);
  }

  get(urlEnding, extraConfig, thenFunction) {
    let errorFunction = undefined;
    if (typeof extraConfig === "function") {
      errorFunction = thenFunction;
      thenFunction = extraConfig;
      extraConfig = undefined;
    }

    let config = this.addDefaultConfig(extraConfig);

    let promise = axios.get(REACT_APP_BACKEND_URL+this.urlPrefix+urlEnding, config)
		.then(thenFunction)
    .catch((error) => this.catchFunction(this, error, errorFunction));
    return promise;
  }

  post(urlEnding, data, extraConfig, thenFunction, errorFunction) {
    if (typeof extraConfig === "function") { // extraConfig is not given
      errorFunction = thenFunction;
      thenFunction = extraConfig;
      extraConfig = undefined;
    }

    let config = this.addDefaultConfig(extraConfig);
    let promise = axios.post(REACT_APP_BACKEND_URL+this.urlPrefix+urlEnding, data, config)
		.then(thenFunction)
    .catch((error) => this.catchFunction(this, error, errorFunction));
    return promise;
  }


  delete(urlEnding, extraConfig, thenFunction) {
    if (typeof extraConfig === "function") {
      thenFunction = extraConfig;
      extraConfig = undefined;
    }

    let config = this.addDefaultConfig(extraConfig);

    let promise = axios.delete(REACT_APP_BACKEND_URL+this.urlPrefix+urlEnding, config)
    .then(thenFunction)
    .catch((error) => this.catchFunction(this, error));
    return promise;
  }

  put(urlEnding, data, extraConfig, thenFunction, errorFunction) {
    if (typeof data === "function") { // data and extraConfig is missing
      errorFunction = extraConfig;
      thenFunction = data;
    }
    if (typeof extraConfig === "function") { // extraConfig is not given
      errorFunction = thenFunction;
      thenFunction = extraConfig;
      extraConfig = undefined;
    }

    let config = this.addDefaultConfig(extraConfig);
    let promise = axios.put(REACT_APP_BACKEND_URL+this.urlPrefix+urlEnding, data, config)
		.then(thenFunction)
    .catch((error) => this.catchFunction(this, error, errorFunction));
    return promise;
  }

  patch(urlEnding, data, extraConfig, thenFunction, errorFunction) {
    if (typeof data === "function") { // data and extraConfig is missing
      errorFunction = extraConfig;
      thenFunction = data;
    }
    if (typeof extraConfig === "function") { // extraConfig is not given
      errorFunction = thenFunction;
      thenFunction = extraConfig;
      extraConfig = undefined;
    }

    let config = this.addDefaultConfig(extraConfig);
    let promise = axios.patch(REACT_APP_BACKEND_URL+this.urlPrefix+urlEnding, data, config)
		.then(thenFunction)
    .catch((error) => this.catchFunction(this, error, errorFunction));
    return promise;
  }

  cancel(message) {
    this.cancelTokenSource.cancel(message);
  }

  refreshUserData(userData) {
    if (userData !== undefined) {
      this.authToken = userData.token();
    } else {
      console.log('userData is not defined!');
    }
  }

  setToken(jwt) {
    this.authToken = jwt;
  }

  // should be private

  shortJwt() {
    if (this.authToken === undefined)
      return 'no_token';
    let parts = this.authToken.split('.');
    return parts[2].substring(0,10);
  }

  addDefaultConfig(extraConfig) {
    let config = extraConfig ?? {};
    config.headers = {
      'Accept': 'application/json',
      'Content-type': 'application/json',
      'PLATFORM-ID': 3,
      ...config.headers,
    };
    config.cancelToken = this.cancelTokenSource.token;
    config.validateStatus = function(status) { return status >= 200 && status < 250 };
    return config;
  }

  catchFunction(self, error, errorFunction) {
    console.log('error')
    if (axios.isCancel(error)) {
      self.cancelHandling(error);
    } else {
      if (errorFunction === undefined) {
        self.errorHandling(error);
      } else {
        errorFunction(error);
      }
    }
  }

  errorHandling(error) {
    console.log(error + 'Problem');
  }

  cancelHandling(error) {
    console.log('Request canceled', error.message ?? '');
  }

}

export default InterAxios;
