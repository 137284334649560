// react imports
import React, {useEffect} from "react";
import ProfileForm from "../components/form-components/ProfileForm"
import {MDBContainer} from 'mdb-react-ui-kit';


const Irjonnekunkpage = () => {

    useEffect(() => {
        setTimeout(() => window.scrollTo({
            top: 0,
            behavior: "smooth"
        }), 100);
    }, []);


    return (
        <>
            <section className="urlap-section" id="urlap-section">
                <MDBContainer style={{maxWidth: "1008px"}} className='p-0'>
                    <div className="kapcsolat-title">
                        <span>Kérdése van? Miben segíthetünk?</span>
                    </div>
                    <ProfileForm />
                </MDBContainer>
            </section>
        </>
    );
};

export default Irjonnekunkpage;
