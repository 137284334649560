import Cookies from "universal-cookie";
import InterAxios from "../Service/InterAxios";

export const getInfopageBySource = async (source) => {

    const cookies = new Cookies();
    const interAxios = new InterAxios('App', () => cookies.get('authtoken')?.token);

    return new Promise((resolve, reject) => {
        interAxios.get(source, function(response) {
            resolve(response.data);
        }, function(error) {
            reject(error.response?.data);
        });
    });

}