import {store} from "../redux/store";
import {setRecurringEvents} from "../redux/actions/setRecurringEvents";
import {listRecurringEvents} from "../action/listRecurringEvents";
import {setCurrentEventPlaceId} from "../redux/actions/setCurrentEventPlaceId";
import {getCurrentEventPlaceId} from "../util/event-place";

export const recurringEventsListLoader = async (args) => {
    store.dispatch(setCurrentEventPlaceId({ eventPlaceId: getCurrentEventPlaceId() }));
    store.dispatch(setRecurringEvents({ recurringEvents: await listRecurringEvents() }));
    return true;
}
