import Cookies from "universal-cookie";
import InterAxios from "../Service/InterAxios";
import {store} from "../redux/store";

const defaultOptions = {
    placeId: undefined,
    limit: undefined,
    to: undefined
}

export const listArchiveEvents = async (options = defaultOptions) => {

    const cookies = new Cookies();
    const interAxios = new InterAxios('App', () => cookies.get('authtoken')?.token);

    if (options.placeId === undefined) {
        options.placeId = store.getState().currentEventPlaceId;
    }

    return new Promise((resolve, reject) => {
        interAxios.get(
            `/events/archive?placeId=${ options.placeId }${ options.limit ? `&limit=${ options.limit }` : `` }${ options.to ? `&to=${ options.to }` : `` }`,
            function (response) {
                resolve(response.data);
            },
            function (error) {
                reject(error.response?.data);
            });
    })

}