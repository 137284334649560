import React from 'react';
import { createRoot } from "react-dom/client";
import './index.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import './assets/scss/style.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {listEventPlaces} from "./action/listEventPlaces";
import {getCurrentEventPlaceId, getEventPlaceAnalytics} from "./util/event-place";
import {injectAnalytics} from "./util/inject-analytics";
const container = document.getElementById("root");
const root = createRoot(container);

if (process.env.REACT_APP_ENVIRONMENT === "production") {
    const ga = getEventPlaceAnalytics(getCurrentEventPlaceId());
    injectAnalytics(ga);
}

listEventPlaces().then(eventPlaces => {
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
});



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();