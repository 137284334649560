import {listGuestEvents} from "../action/listGuestEvents";
import {store} from "../redux/store";
import {setGuestEvents} from "../redux/actions/setGuestEvents";
import {setCurrentEventPlaceId} from "../redux/actions/setCurrentEventPlaceId";
import {getCurrentEventPlaceId} from "../util/event-place";

export const nextExhibitionsListLoader = async (args) => {
    store.dispatch(setCurrentEventPlaceId({ eventPlaceId: getCurrentEventPlaceId() }));
    store.dispatch(setGuestEvents({ guestEvents: await listGuestEvents() }));

    return { loadedAt: new Date() };
}
