import React from "react";
import {useNavigate} from "react-router-dom";
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import tovabb from "../assets/media/icons/tovabb nyil.svg";
import ugyvezeto from "../assets/media/images/ugyvezeto.png";
import szakmaiIgazgato from "../assets/media/images/szakmai-igazgato.png";
import szervezetiIgazgato from "../assets/media/images/szervezeti-igazgato.png";
import {useSelector} from "react-redux";

const OrganizationstructurePage = (props) => {

    const navigate = useNavigate();
    const { infopages } = useSelector(state => state);

    return (
        <>
            {props.infopageById && (<section className="structure-section" id="structure-section">
                <MDBContainer style={{maxWidth: "1008px"}} className='p-0'>
                    <div className="kapcsolat-map-div">
                        <div className="one-col-div">
                            <div className="navigate-div" style={{ marginTop: 0 }}>
                                <button onClick={() => {
                                    navigate('/home')
                                }}>Főoldal
                                </button>
                                <img src={tovabb} alt="tovabb"/>
                                <button onClick={() => {
                                    navigate(infopages.find(i => i.title === "Szervezetünk") ? ('/infosfooldal/' + infopages.find(i => i.title === "Szervezetünk").id) : `/home`)
                                }}>Szervezetünk</button>
                                <img src={tovabb} alt="tovabb"/>
                                <button>Szervezeti felépítés</button>
                            </div>
                            <div className="up-div mb-0">
                                <h3>Szervezeti felépítés</h3>

                                <div className="organization-structure-header">

                                    <div className="organization-structure-header-title">
                                        Vezetőink
                                    </div>

                                    <div className="organization-structure-header-persons">

                                        <div className="organization-structure-person">

                                            <div className="organization-structure-person-image">
                                                <img src={ugyvezeto} alt="Lukács Zoltán"/>
                                            </div>
                                            <div className="organization-structure-person-name">Lukács Zoltán</div>
                                            <div className="organization-structure-person-position">ügyvezető</div>
                                            <div className="organization-structure-person-organization">Agora Nonprofit
                                                Kft.
                                            </div>

                                        </div>

                                        <div className="organization-structure-person">

                                            <div className="organization-structure-person-image">
                                                <img src={szakmaiIgazgato} alt="Seffer Edina"/>
                                            </div>
                                            <div className="organization-structure-person-name">Seffer Edina</div>
                                            <div className="organization-structure-person-position">szakmai igazgató
                                            </div>
                                            <div className="organization-structure-person-organization">A Vértes
                                                Agorája
                                            </div>

                                        </div>

                                        <div className="organization-structure-person">

                                            <div className="organization-structure-person-image">
                                                <img src={szervezetiIgazgato} alt="Lévai Viktória"/>
                                            </div>
                                            <div className="organization-structure-person-name">Lévai Viktória</div>
                                            <div className="organization-structure-person-position">szervezeti
                                                igazgató
                                            </div>
                                            <div className="organization-structure-person-organization">Agora Nonprofit
                                                Kft.
                                            </div>

                                        </div>

                                    </div>

                                </div>

                                {props.infopageById.map((k, n) =>
                                    (
                                        <div className="structure" key={n}>
                                            <div className="title">
                                                <span>{k.name}</span>
                                            </div>
                                            <MDBRow className="structure-row">
                                                {k.fellowWorkers.map((l, m) => {
                                                    return (
                                                        <MDBCol md="3" size="12" key={m} className="px-2">
                                                            <div className="structure-card">
                                                                {l.name.split('\u2028').map((o, p) => {
                                                                    return (
                                                                        <p key={p}>{o}</p>
                                                                    )
                                                                })}
                                                                {l.position !== "" && (<>
                                                                        {l.position.split('\u2028').map((o, p) => {
                                                                            return (
                                                                                <div key={p} className="d-flex">
                                                                                    {l.position_url === null || l.position_url.length === 0 ?
                                                                                        <span>{o}</span>
                                                                                        :
                                                                                        <a onClick={() => window.open(l.position_url, '_blank')}
                                                                                           style={{cursor: "pointer"}}>{o}</a>
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </>
                                                                )}
                                                                {l.company !== "" && (<>
                                                                        {l.company.split('\u2028').map((o, p) => {
                                                                            return (
                                                                                <div key={p} className="d-flex">
                                                                                    {l.company_url === null ?
                                                                                        <span>{o}</span>
                                                                                        :
                                                                                        <a onClick={() => window.open(l.company_url, '_blank')}
                                                                                           style={{cursor: "pointer"}}>{o}</a>
                                                                                    }

                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </MDBCol>
                                                    )
                                                })}
                                            </MDBRow>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </MDBContainer>
            </section>)}
        </>

    );
};

export default OrganizationstructurePage;
