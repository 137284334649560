import moment from "moment/moment";

export function formatEventDate(event, onlyFrom = false, detail = false) {
    if (event.all_day) {
        return `egész nap`
    } else {
        if (onlyFrom === true || moment.unix(event.dateFrom).locale('hu').format('MM-DD') !== moment.unix(event.dateTo).locale('hu').format('MM-DD')) {
            if (detail === true) {
                return moment.unix(event.dateFrom).locale('hu').format('YYYY. MMMM DD. HH:mm') + ` - ` + moment.unix(event.dateTo).locale('hu').format('YYYY. MMMM DD. HH:mm');
            } else {
                return moment.unix(event.dateFrom).locale('hu').format('HH:mm');
            }
        } else {
            return moment.unix(event.dateFrom).locale('hu').format('HH:mm') + ` - ` + moment.unix(event.dateTo).locale('hu').format('HH:mm');
        }
    }
}