import Cookies from "universal-cookie";
import InterAxios from "../Service/InterAxios";
import {store} from "../redux/store";

export const listRecurringEvents = async (placeId = undefined) => {

    const cookies = new Cookies();
    const interAxios = new InterAxios('App', () => cookies.get('authtoken')?.token);

    if (placeId === undefined) {
        placeId = store.getState().currentEventPlaceId;
    }

    return new Promise((resolve, reject) => {
        interAxios.get('/events/recurring?placeId=' + placeId, function(response) {
            resolve(response.data);
        }, function(error) {
            reject(error.response?.data);
        });
    });

}