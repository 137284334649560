// react imports
import React from "react";
import { useNavigate } from "react-router-dom";
import {BlurhashImage} from "../blurhash/BlurhashImage";

const GaleriaKategoriaCard = (props) => {

  
  const navigate = useNavigate();

  return (
    <div className="program-card-div"  onClick={() => navigate('/galeriadetailpage/' + props.msg.id)}>
        <div className="image-div">
            {
                props.msg.image ? (
                    <BlurhashImage image={props.msg.image} blurhash={props.msg.imageBlurhash} />
                ) : null
            }
        </div>
        {/*
        <div className="datum-div">
            <span>{moment.unix(props.msg.datum).locale('hu').format('YYYY.MM.DD.')}</span>
                                                                                  </div>*/}
        <div className="text-div">
            <span>{props.msg.title}</span>
        </div>
    </div>
  );
};

export default GaleriaKategoriaCard;
