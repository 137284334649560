import {store} from "../redux/store";
import {listInfopages} from "../action/listInfopages";
import {getInfopageBySource} from "../action/getInfopageBySource";
import {getInfopageById} from "../action/getInfopageById";
import {getReplaceText} from "../util/get-replace-text";
import {setInfopage} from "../redux/actions/setInfopage";
import {setInfoPageSource} from "../redux/actions/setInfoPageSource";

export const infopageLoader = async (args) => {
    const url = new URL(args.request.url);
    const pathname = url.pathname.substring(7).replaceAll('/', '%2F');
    const infopages = await listInfopages();

    let array = [];

    const flattenArray = (arr, path) => {
        for (const a of arr) {
            const pathname = path.length === 0 ? `${getReplaceText(a.title)}`: `${path}/${getReplaceText(a.title)}`;
            if (a.children && a.children.length >= 1) {
                flattenArray(a.children, pathname);
                array.push({...a, pathname: pathname});
            } else {
                array.push({...a, pathname: pathname});
            }
        }
    }

    flattenArray(infopages, '');
    const matchingInfo = array.filter(k => encodeURIComponent(k.pathname) === pathname)[0];
    if(matchingInfo.source !== null && matchingInfo.source !== "" && matchingInfo.source !== undefined){
        store.dispatch(setInfopage(await getInfopageBySource(matchingInfo.source)));
    }else {
        store.dispatch(setInfopage(await getInfopageById(matchingInfo.id)));
    }
    store.dispatch(setInfoPageSource(matchingInfo.source))

    return true;
}
