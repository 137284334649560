import React, { useState, useEffect } from "react";
import {Link, useNavigate} from "react-router-dom";
import { MDBContainer } from "mdb-react-ui-kit";
import facebook_white from "../../../assets/media/icons/Facebook white.svg"
import youtube_white from "../../../assets/media/icons/Youtube white.svg"
import instagram_white from "../../../assets/media/icons/Instagram white.svg"
import appentum from "../../../assets/media/logos/appentum.svg"
import Cookies from 'universal-cookie';
import {useSelector} from "react-redux";
import {getEventPlace} from "../../../util/event-place";
const cookies = new Cookies();

const Footer = () => {
  const navigate = useNavigate();
  const [onMouseOverFacebook, setOnMouseOverFacebook] = useState(false);
  const [onMouseOverYoutube, setOnMouseOverYoutube] = useState(false);
  const [onMouseOverInstagram, setOnMouseOverInstagram] = useState(false);
  const [direction, setDirection] = useState(window.outerWidth <= 810 ? "vertical" : "horizontal");

  const { eventPlace, infopages } = useSelector(state => state);

  const onAddNewsletter = () => {
     navigate('/hirlevelpage')
  }

  const handleWindowSizeChange = () => {
    setDirection(window.visualViewport.width <= 810 ? "vertical" : "horizontal");
  };

  useEffect(() => {
    window.visualViewport.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.visualViewport.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

    return (
      <>
      {direction === "horizontal" ?
        <footer className="agora-footer" id="agora-footer">
          <MDBContainer style={{maxWidth: "1048px", height: "100%"}} className='p-0'>
            <div className="footer-div">
              <div className="footer-culomn-div">
                <div className="elso-oszlop">
                {eventPlace && (
                  <img src={require("../../../assets/media/logos/" + getEventPlace(eventPlace.id) + "_logo_feher.svg")} alt="ava-logo"/> )}
                  {getEventPlace(eventPlace.id) === "ava" ?
                    <div>
                    <p onClick={() => {
                      navigate('/kapcsolat')}} style={{cursor: "pointer"}}>Elérhetőség</p>
                    <span>{eventPlace.address}</span><br/>
                    <span>{eventPlace.phone}</span><br/>
                    <span>{eventPlace.email}</span><br/><br/>
                    </div>:
                    <div >
                    <p onClick={() => { navigate('/elerhetosegek')}} style={{cursor: `pointer`}}>Elérhetőség</p>
                    <span>{eventPlace.address}</span><br/>
                    <span>{eventPlace.phone}</span><br/>
                    <span>{eventPlace.email}</span><br/><br/>
                    </div>
                  }
                  
                </div>
                {getEventPlace(eventPlace.id) === "ava" ?
                    <div className="masodik-oszlop">
                      <p style={{cursor: "pointer"}} onClick={() => {
                        navigate('/kapcsolat')
                      }}>Nyitvatartás</p>
                      {eventPlace.openingHours?.openingHours.map((k, n) => {
                        return (
                            <div key={n}>
                              <span>{k.title + ": " + k.desription}</span>
                            </div>
                        )
                      })}
                      <span>{eventPlace.openingHours.openingHoursText}</span>

                      <p onClick={() => {
                        navigate('/kapcsolat')
                      }} className="jegyvasarlas" style={{cursor: "pointer"}}>Jegypénztár</p>
                      {eventPlace.openingHours?.openingHoursTicketOffice.map((k, n) => {
                        return (
                            <div key={n}>
                              <span>{k.title + ": " + k.desription}</span>
                            </div>

                        )
                      })}
                      {eventPlace.openingHours?.lunchBreakTicketOffice ? (
                          <div>
                            <span
                                className="normal-text">Ebédidő: {eventPlace.openingHours?.lunchBreakTicketOffice}</span>
                          </div>
                      ) : null}
                      <span>{eventPlace.openingHours.openingHoursTicketOfficeText}</span>
                    </div> :
                    <div className="masodik-oszlop">
                      <p onClick={() => {
                        navigate('/elerhetosegek')
                      }} style={{cursor: `pointer`}}>Nyitvatartás</p>
                      {eventPlace.openingHours?.openingHours.map((k, n) => {
                        return (
                            <div key={n}>
                              <span>{k.title + ": " + k.desription}</span>
                            </div>
                        )
                      })}
                      <span>{eventPlace.openingHours.openingHoursText}</span>
                    </div>}
                <div className="harmadik-oszlop">
                <p>Kövessen minket!</p>
                  {eventPlace && (
                          <>
                            <img
                                src={!onMouseOverFacebook ? require("../../../assets/media/icons/" + getEventPlace(eventPlace.id) + "_Facebook.svg") : facebook_white}
                                alt="facebook" style={{cursor: "pointer"}}
                                onMouseOver={() => setOnMouseOverFacebook(true)}
                                onMouseLeave={() => setOnMouseOverFacebook(false)}
                                onClick={() => window.open(eventPlace.facebookUrl, '_blank')}/>

                            <img
                                src={!onMouseOverInstagram ? require("../../../assets/media/icons/" + getEventPlace(eventPlace.id) + "_Instagram.svg") : instagram_white}
                                alt="instagram" style={{cursor: "pointer"}}
                                onMouseOver={() => setOnMouseOverInstagram(true)}
                                onMouseLeave={() => setOnMouseOverInstagram(false)}
                                onClick={() => window.open(eventPlace.instagramUrl, '_blank')}/>

                            <img
                                src={!onMouseOverYoutube ? require("../../../assets/media/icons/" + getEventPlace(eventPlace.id) + "_Youtube.svg") : youtube_white}
                                alt="youtube" style={{cursor: "pointer"}}
                                onMouseOver={() => setOnMouseOverYoutube(true)}
                                onMouseLeave={() => setOnMouseOverYoutube(false)}
                                onClick={() => window.open(eventPlace.youtubeUrl, '_blank')}/>
                          </>
                      )}

                  <p>Agora hírlevél</p>
                  <button type="button" className="feliratkozom-button" style={{width: "189px !important"}}
                          onClick={() => onAddNewsletter()}><span>FELIRATKOZOM</span></button>
                </div>


              </div>
              <div className="jogi-div">

              <a onClick={() => { navigate('/irjonnekunk')}} style={{cursor: "pointer"}}>Írjon nekünk!</a><br/>
                  
                  {infopages?.filter(k => k.title === "Adatkezelési tájékoztatók").length > 0 && (
                    <a  onClick={() => {
                                        navigate('/infosfooldal/' + infopages.filter(k => k.title === "Adatkezelési tájékoztatók")[0]?.id)}} style={{cursor: "pointer"}}>Adatvédelem</a>
                  )}
                    <a  
                    onClick={() => {
                                    navigate('/infos/szervezetunk/agora_nonprofit_kft')}} style={{cursor: "pointer"}}>Közérdekű adatok</a>
                    <a onClick={() => { navigate('/impresszum')}} style={{cursor: "pointer"}}>Impresszum</a>
                    <a onClick={() => { window.open("https://avertesagoraja.hu/", '_blank')}} style={{cursor: "pointer"}}>Akadálymentesítés</a>
                    <a onClick={() => { window.open("https://avertesagoraja.hu/", '_blank')}} style={{cursor: "pointer"}}>Archívum</a>
                    
              </div>
              <div className="footer-licensz-div">
                <Link to="https://agoranonprofit.hu/" target="_blank" style={{ color: `white` }}>© 2024 Agora Nonprofit Kft. - Minden jog fenntartva!</Link>
                <Link to="https://appentum.hu" target="_blank"><img src={appentum} alt="appentum" /></Link>
              </div>
            </div>
          </MDBContainer>
        </footer>:
        <footer className="agora-footer-respo" id="agora-footer-respo">
          <MDBContainer style={{maxWidth: "1259px", height: "100%"}} className='p-0'>
            <div className="footer-div">
              <div className="footer-culomn-div">
                <div className="elso-oszlop">
                  {eventPlace && (
                    <img className="logo-img" src={require("../../../assets/media/logos/" + getEventPlace(eventPlace.id)  + "_logo_feher.svg")} alt="ava-logo"/>
                  )}
                  {getEventPlace(eventPlace.id) === "ava" ?
                  <div onClick={() => { navigate('/kapcsolat')}}>
                  <p>Elérhetőség</p>
                  <span className="normal-text">{eventPlace.address}</span><br/>
                  <span className="normal-text">{eventPlace.phone}</span><br/>
                  <span className="normal-text">{eventPlace.email}</span><br/><br/>
                 
                
                  <p>Nyitvatartás</p>
                  {eventPlace.openingHours?.openingHours.map((k,n) => {return (
                    <div key={n}>
                      <span className="normal-text">{k.title + ": " + k.desription}</span>
                    </div> 
                  )})}
                
                  <p className="jegyvasarlas">Jegypénztár</p>
                  {eventPlace.openingHours?.openingHoursTicketOffice.map((k,n) => {return (
                    <div key={n}>
                      <span className="normal-text">{k.title + ": " + k.desription}</span>
                    </div>
                  )})}
                  </div>:
                  <div>
                  <p onClick={() => { navigate('/elerhetosegek')}} style={{cursor: `pointer`}}>Elérhetőség</p>
                  <span className="normal-text">{eventPlace.address}</span><br/>
                  <span className="normal-text">{eventPlace.phone}</span><br/>
                  <span className="normal-text">{eventPlace.email}</span><br/><br/>
                 
                
                  <p onClick={() => { navigate('/elerhetosegek')}} style={{cursor: `pointer`}}>Nyitvatartás</p>
                  {eventPlace.openingHours?.openingHours.map((k,n) => {return (
                    <div key={n}>
                      <span className="normal-text">{k.title + ": " + k.desription}</span>
                    </div> 
                  )})}
                  </div>}
                  <p>Kövessen minket!</p>
                  {eventPlace &&(
                          <>
                            <img className="social-logos"
                                 src={!onMouseOverFacebook ? require("../../../assets/media/icons/" + getEventPlace(eventPlace.id) + "_Facebook.svg") : facebook_white}
                                 alt="facebook" onMouseOver={() => setOnMouseOverFacebook(true)}
                                 onMouseLeave={() => setOnMouseOverFacebook(false)}
                                 onClick={() => window.open(eventPlace.facebookUrl, '_blank')}/>

                            <img className="social-logos"
                                 src={!onMouseOverInstagram ? require("../../../assets/media/icons/" + getEventPlace(eventPlace.id) + "_Instagram.svg") : instagram_white}
                                 alt="instagram" onMouseOver={() => setOnMouseOverInstagram(true)}
                                 onMouseLeave={() => setOnMouseOverInstagram(false)}
                                 onClick={() => window.open(eventPlace.instagramUrl, '_blank')}/>

                            <img className="social-logos"
                                 src={!onMouseOverYoutube ? require("../../../assets/media/icons/" + getEventPlace(eventPlace.id) + "_Youtube.svg") : youtube_white}
                                 alt="youtube" onMouseOver={() => setOnMouseOverYoutube(true)}
                                 onMouseLeave={() => setOnMouseOverYoutube(false)}
                                 onClick={() => window.open(eventPlace.youtubeUrl, '_blank')}/>
                          </>
                      )}

                  <p>Agora hírlevél</p>

                  <button type="button" className="feliratkozom-button-respo"
                          style={{width: "189px !important", marginBottom: "30px"}} onClick={() => onAddNewsletter()}>
                    <span className="span-button">FELIRATKOZOM</span></button>
                  <a onClick={() => {
                     navigate('/impresszum')}}>Impresszum</a><br/>
                  <a onClick={() => { navigate('/irjonnekunk')}}>Írjon nekünk!</a><br/>
                  {infopages !== undefined && (
                    <>
                    {infopages.filter(k => k.title === "Adatkezelési tájékoztatók").length > 0 && (
                    <a  onClick={() => {
                                        navigate('/infosfooldal/' + infopages.filter(k => k.title === "Adatkezelési tájékoztatók")[0]?.id)}}>Adatvédelem</a>
                  )}
                  <br/><a  onClick={() => {
                                  navigate('/infos/szervezetunk/agora_nonprofit_kft')}}>Közérdekű adatok</a><br/>
                    </>
                  )}
                    <a onClick={() => { window.open("https://avertesagoraja.hu/", '_blank')}}>Akadálymentesítés</a><br/>
                    <a onClick={() => { window.open("https://avertesagoraja.hu/", '_blank')}}>Archívum</a>
                </div>
              </div>
              <div className="footer-licensz-div">
                <Link to="https://agoranonprofit.hu/" target="_blank" style={{ color: `white` }}>© 2024 Agora Nonprofit Kft. - Minden jog fenntartva!</Link>
                <img src={appentum} alt="appentum"/>
              </div>
            </div>
          </MDBContainer>
        </footer>}
      </>
      
    );
};

export default Footer;
