import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MDBContainer } from 'mdb-react-ui-kit';
import tovabb from "../assets/media/icons/tovabb nyil.svg"

const Impresszumpage = () => {

  const navigate = useNavigate();
  const [direction, setDirection] = useState(window.outerWidth);

  const handleWindowSizeChange = () => {
    setDirection(window.visualViewport.width);
  };

  useEffect(() => {
    window.visualViewport.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.visualViewport.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  useEffect(() => {
    setTimeout(() => window.scrollTo({
      top: 0,
      behavior: "smooth"
    }), 100);
  }, []);


  return (
    <>
    <section className="kapcsolat-map-section" id ="kapcsolat-map-section" style={{ minHeight: "calc(100vh - 617px)"}}>
      <MDBContainer style={{maxWidth: "1008px"}} className='p-0'>
        <div className="kapcsolat-map-div">
          <div className="one-col-div">
            <div className="navigate-div">
              <button onClick={() => { navigate('/home')}}>Főoldal</button>
              <img src={tovabb} alt="tovabb"/>
              <button onClick={() => navigate('/kapcsolat')}>Impresszum</button>
            </div>
            <div className="up-div">
              <h3>Impresszum</h3>
              <p>A weboldal fenntartója: <span>Agora Sport és Szabadidő Közhasznú Nonprofit Kft.</span></p>
              <p>Székhely: <span>2800 Tatabánya Szent Borbála út 19.</span></p>
              <p>Elérhetőségek: <span>+36 34 688-100, <a href={"mailto:info@avertesagoraja.hu"}>info@avertesagoraja.hu</a></span></p>
              <p>Regisztrációs bíróság: <span>Tatabányai Törvényszék Cégbírósága. Nyilvántartási szám: 11-09-015404</span></p>
              <p>Adószám: <span>20579339-2-11</span></p>
              <p>Tárhelyszolgáltató:</p>
              <p>Cégnév: <span>Appentum Kft.</span></p>
              <p>Székhely: <span>9025, Győr, Kossuth Lajos utca 12.</span></p>
              <p>Cégjegyzékszám: <span>08-09-027613</span></p>
              <p>Adószám: <span>23572843-2-08</span></p>
              <p>Honlap: <a href="www.appentum.hu" target="_blank"  rel="noopener noreferrer">www.appentum.hu</a></p>
            </div>
            
          </div>
          
        </div>
      </MDBContainer>
      {direction > 811 && (
          <div className="background-div"/>
        )}
    </section>
    </>
  );
};

export default Impresszumpage;
