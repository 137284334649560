import React, {useState, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import CarouselComponent from '../components/carousel/CarouselComponent.js'
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import {
    MDBContainer
} from 'mdb-react-ui-kit';

import tovabb from "../assets/media/icons/tovabb nyil.svg"
import {BlurhashImage} from "../components/blurhash/BlurhashImage";
import {useSelector} from "react-redux";
import {getEventPlaceUrl} from "../util/get-event-place-url";

const BemutatkozasPage = () => {

    const navigate = useNavigate();
    const params = useParams();

    const [sliderImages, setSliderImages] = useState([]);
    const [open, setOpen] = useState(false);
    const direction = window.outerWidth;

    const {eventPlace, eventPlaces} = useSelector(state => state);

    useEffect(() => {
        setTimeout(() => window.scrollTo({
            top: 0,
            behavior: "smooth"
        }), 100);
    }, []);

    if (params.muvhaz) {
        if (eventPlaces) {
            const place = eventPlaces.find(k => k.id === parseInt(params.muvhaz));
            window.location.href = getEventPlaceUrl('/bemutatkozas', place.id);
            return <></>;
        } else {
            return <></>;
        }
    }

    return (
        <>
            <section className="kapcsolat-map-section" id="kapcsolat-map-section">
                <MDBContainer style={{maxWidth: "1008px"}} className='p-0'>
                    <div className="kapcsolat-map-div">
                        <div className="one-col-div">
                            <div className="navigate-div" style={{marginTop: 0}}>
                                <button onClick={() => {
                                    navigate('/home')
                                }}>Főoldal
                                </button>
                                <img src={tovabb} alt="tovabb"/>
                                <button>Bemutatkozás</button>
                            </div>
                            <div className="up-div mb-0">
                                <h3>Bemutatkozás</h3>
                                <div className="description"
                                     dangerouslySetInnerHTML={{__html: eventPlace.description,}}></div>
                                <div className="gallery">
                                    {eventPlace.galleries && eventPlace.galleries.length > 0 && (
                                        <>
                                            {eventPlace.galleries.length === 1 ?
                                                <div className="image-items">
                                                    <img className="gallery-image-one"
                                                         src={eventPlace.galleries[0].image} alt=""/>
                                                </div>
                                                :
                                                <MDBContainer fluid className="gallery">
                                                    <div className="gallery-title">
                                                        <span>Galéria</span>
                                                    </div>
                                                    <CarouselComponent gallery={eventPlace.galleries}
                                                                       openLightboxlick={() => {
                                                                           setSliderImages(eventPlace.galleries);
                                                                           setOpen(!open)
                                                                       }}/>
                                                </MDBContainer>
                                            }


                                        </>

                                    )}
                                    {eventPlace.youtubeEmbedUrl && (
                                        <div className="video">
                                            <iframe src={eventPlace.youtubeEmbedUrl + "?autoplay=0&controls=1&loop=0"}
                                                    height={window.innerWidth / 1920 > 1 ? 412 : (window.innerWidth / 1920) * 412}
                                                    width={window.innerWidth / 1920 > 1 ?
                                                        710 : (window.innerWidth / 1920) * 710} allow="autoplay"
                                                    title="myFrame"></iframe>
                                        </div>
                                    )}
                                </div>
                                {eventPlace && eventPlace.image ? (
                                    <div className="image-div">
                                        <BlurhashImage image={eventPlace.image} blurhash={eventPlace.imageBlurhash}/>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </MDBContainer>
                {direction > 811 && (
                    <div className="background-div"/>
                )}
            </section>
            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={sliderImages.map(k => {
                    return ({src: k.image})
                })}
                plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
            />
        </>
    );
};

export default BemutatkozasPage;
