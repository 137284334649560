import Cookies from "universal-cookie";
import InterAxios from "../Service/InterAxios";

export const getInfopageById = async (id) => {

    const cookies = new Cookies();
    const interAxios = new InterAxios('App', () => cookies.get('authtoken')?.token);

    return new Promise((resolve, reject) => {
        interAxios.get('/infopages/' + id, function(response) {
            resolve(response.data);
        }, function(error) {
            reject(error.response?.data);
        });
    });

}