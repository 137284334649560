// react imports
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import 'react-calendar/dist/Calendar.css';
import moment from 'moment'
// CUSTOM COMPONENTS
import ProgramCard from "../components/cards/ProgramCard"

import tovabb from "../assets/media/icons/tovabb nyil.svg"
import { MDBContainer, 
         MDBCol, 
         MDBRow
          } from 'mdb-react-ui-kit';
import {pushArchiveEvents} from "../redux/actions/pushArchiveEvents";
import {listArchiveEvents} from "../action/listArchiveEvents";
import {useDispatch, useSelector} from "react-redux";


const ArchivProgramokPage = () => {

  moment.updateLocale('hu', {
    months : [
        "Január", "Február", "Március", "Április", "Május", "Június", "Július",
        "Augusztus", "Szeptember", "Október", "November", "December"
    ]
  }); 
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [direction, setDirection] = useState(window.outerWidth);
  const { archiveEvents, areMoreArchiveEvents } = useSelector(state => state);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);


  const loadMorePrograms = async () => {
    dispatch(pushArchiveEvents({ archiveEvents: await listArchiveEvents({ limit: 9, to: archiveEvents[archiveEvents.length -1].dateTo -1 }) }))
  };

  const handleWindowSizeChange = () => {
    setDirection(window.visualViewport.width);
  };

  useEffect(() => {
    window.visualViewport.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.visualViewport.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <>
    <section className="kovetkezo-programok-section events-page" id ="kovetkezo-programo-section">
      <MDBContainer style={{maxWidth: "1008px"}} className='p-0'>
        
        <div className="navigate-div" style={{marginTop: 0}}>
            <button onClick={() => { navigate('/home')}}>Főoldal</button>
            <img src={tovabb} alt="tovabb"/>
            <button>Archívum</button>
          </div>
          <div>
              <div className="program-title">
                <span>Archívum</span>
              </div>
              <MDBRow className="m-0 programok-row">
                {archiveEvents.map((k,n) => {return (
                  <MDBCol md="4" className="programok-col px-0" key={n}>
                    <ProgramCard msg={k} categoryName={"Archívum"} linkText={"archivum"}/>
                  </MDBCol>
                )})}
              </MDBRow>
              {areMoreArchiveEvents && (
              <div className="loadmore-div">
              <button type="button" className="muster-button kiemelt-next" onClick={loadMorePrograms}><span>Továbbiak </span></button>
            </div>)}
            </div>
      </MDBContainer>
    </section>
    </>
  );
};

export default ArchivProgramokPage;
