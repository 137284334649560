// react imports
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MDBContainer } from 'mdb-react-ui-kit';
import tovabb from "../assets/media/icons/tovabb nyil.svg"
import {useSelector} from "react-redux";
import {OpenStreetMap} from "../components/openstreetmap/OpenStreetMap";

const ElerhetosegekPage = () => {

  const navigate = useNavigate();

  const direction = window.outerWidth;
  useEffect(() => {
    setTimeout(() => window.scrollTo({
      top: 0,
      behavior: "smooth"
    }), 100);
  }, []);
  
  const { eventPlace } = useSelector(state => state)


  return (
    <>
    <section className="kapcsolat-map-section-2" id="kapcsolat-map-section">
      <MDBContainer style={{maxWidth: "1008px"}} className='p-0'>
      <div className="navigate-div" style={{marginTop: 0}}>
              <button onClick={() => { navigate('/home')}}>Főoldal</button>
              <img src={tovabb} alt="tovabb"/>
              <button >Elérhetőségek</button>
            </div>
        <div className="kapcsolat-map-div">
        
          <div className="left-col-div">
            
            <div className="up-div">
              <h3>Elérhetőségek</h3>
              <span>{eventPlace.name}</span>
              <span>{"Cím: " + eventPlace.address}</span>
              {eventPlace.phone !== null && (
                <span>{"Telefon: " + eventPlace.phone}</span>
              )}
               {eventPlace.email !== null && (
                <span>E-mail: <a href={"mailto:" + eventPlace.email}>{eventPlace.email}</a></span>
              )}
              
            </div>
            <div className="down-div">
              <p>Nyitvatartás:</p>
              {eventPlace.openingHours?.openingHours.map((k, n) => {
                return (
                    <span key={n}>{k.title + ": " + k.desription}</span>
                )
              })}
              <span>{eventPlace.openingHours.openingHoursText}</span>

              <p>A jegypénztár nyitvatartása:</p>
              {eventPlace.openingHours?.openingHoursTicketOffice.map((k, n) => {
                return (
                    <span key={n}>{k.title + ": " + k.desription}</span>
                )
              })}
              <span>{eventPlace.openingHours.openingHoursTicketOfficeText}</span>
            </div>
            {direction <= 811 && (
                <div className="map-keret" id="map-keret">
                <OpenStreetMap lat={eventPlace.lat} lng={eventPlace.lng} />
            </div>)}
          </div>
          {direction > 811 && (
          <div className="right-col-div">
            <div className="map-keret" id="map-keret">
                <OpenStreetMap lat={eventPlace.lat} lng={eventPlace.lng} />
            </div>
          </div>)}
        </div>
      </MDBContainer>
    </section>
    
    </>
  );
};

export default ElerhetosegekPage;
