import { useNavigate } from "react-router-dom";
import moment from 'moment'
import {BlurhashImage} from "../blurhash/BlurhashImage";
import {useEffect, useRef, useState} from "react";

const HirekCard = (props) => {

    const titleRef = useRef();
    const [fontSize, setFontSize] = useState(22);
    const descRef = useRef();
    const [descFontSize, setDescFontSize] = useState(18);
    const [descLineHeight, setDescLineHeight] = useState(26);

    useEffect(() => {
        if (window.outerWidth > 811 && titleRef.current) {

            if (fontSize === 16) {
                return;
            }

            if (titleRef.current.scrollHeight > 56) {
                setFontSize(fontSize - 1);
            }
        }
    }, [fontSize, titleRef]);

    useEffect(() => {
        if (window.outerWidth > 811 && descRef.current) {

            if (descFontSize === 14) {
                setDescLineHeight(17);
                return;
            }

            if (descRef.current.scrollHeight > 52) {
                setDescFontSize(descFontSize - 1);
            }
        }
    }, [descFontSize, descRef]);

  moment.updateLocale('hu', {
    months : [
        "Január", "Február", "Március", "Április", "Május", "Június", "Július",
        "Augusztus", "Szeptember", "Október", "November", "December"
    ]
  }); 
  const navigate = useNavigate();
  return (
    <div className="hirek-card-div" onClick={() => {navigate('/hirek/hirekdetailpage/' + props.msg.id);}}>
        <div className="image-div">
            <BlurhashImage image={props.msg.listImage} blurhash={props.msg.listImageBlurhash} />
        </div>
        <div className="title-div">
            <p ref={ titleRef } style={fontSize < 22 ? { fontSize: `${ fontSize }px` } : undefined}>{props.msg.title}</p>
            <span>{moment.unix(props.msg.datePublished).locale('hu').format('YYYY.MM.DD.')}</span>
        </div>
        <div className="text-div">
            <div className="description">
              <span ref={ descRef } style={descFontSize < 18 ? { fontSize: `${ descFontSize }px`, lineHeight: `${descLineHeight}px` } : undefined}>{props.msg.lead}</span>
            </div>
        </div>
    </div>
  );
};

export default HirekCard;
