import {store} from "../redux/store";
import {setCurrentEventPlaceId} from "../redux/actions/setCurrentEventPlaceId";
import {getCurrentEventPlaceId} from "../util/event-place";
import {setGuestEvents} from "../redux/actions/setGuestEvents";
import {listGuestEvents} from "../action/listGuestEvents";
import {listArchiveEvents} from "../action/listArchiveEvents";
import {setArchiveEvents} from "../redux/actions/setArchiveEvents";

export const archiveEventsLoader = async (args) => {
    store.dispatch(setCurrentEventPlaceId({ eventPlaceId: getCurrentEventPlaceId() }));
    store.dispatch(setArchiveEvents({ archiveEvents: await listArchiveEvents({ limit: 9 }) }));
    return true;
}
