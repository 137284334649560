import Cookies from "universal-cookie";
import InterAxios from "../Service/InterAxios";

export const sendMessage = async (message) => {

    const cookies = new Cookies();
    const interAxios = new InterAxios('App', () => cookies.get('authtoken')?.token);

    return new Promise((resolve, reject) => {
        interAxios.post('/writeus/message', message,function (response) {
            resolve(response.data);
        }, function (error) {
            reject(error.response?.data);
        });
    });

}